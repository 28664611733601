/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Elements
import HeroDefault from 'components/elements/HeroDefault'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'

const Content = styled(ParseContent)`
  font-family: ${props => props.theme.font.family.secondary};

  h2, h3, h4 {
    color: ${props => props.theme.color.text.third};
    font-weight: ${props => props.theme.font.weight.xl};
  }
`

const PageTemplate = ({
  data: {
    page: { path, yoast_meta: yoast, acf }, 
  },
}) => {
  return (
    <Layout extraSpacing >
      <SEO yoast={yoast} path={path} />

      <HeroDefault home fields={acf.banner} />

      <div className="container py-lg-5 py-3">
        <Content content={acf.text.description} />
      </div>

    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      ...privacyPageFragment
    }
  }
`

export default PageTemplate
